<template>
  <div class="bg-white py-4 md:py-10 md:px-20">
    <header class="mx-auto container px-4 xl:px-0">
      <div class="flex items-center justify-between">
        <a class="custom-foreground-color font-bold text-2xl cursor-pointer" @click="$router.push('/')">
          MINTRY
        </a>
          <div>
            <div @click="show = true" class="sm:block md:hidden lg:hidden text-gray-500 hover:text-gray-700 focus:text-gray-700 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="4" y1="6" x2="20" y2="6" />
                <line x1="4" y1="12" x2="20" y2="12" />
                <line x1="4" y1="18" x2="20" y2="18" />
              </svg>
            </div>
            <div id="menu" class="md:block lg:block" :class="{ 'hidden': !show }">
              <div @click="show = false" class="block md:hidden lg:hidden text-gray-500 hover:text-gray-700 focus:text-gray-700 fixed focus:outline-none z-30 top-0 pt-4">
                <svg aria-haspopup="true" aria-label="Main Menu" xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </div>
              <ul class="font-bold flex md:flex text-3xl md:text-base lg:text-base lg:flex flex-col md:flex-row lg:flex-row justify-center items-center fixed md:relative lg:relative top-0 bottom-0 left-0 right-0 bg-white z-20">
                <li class="md:mr-8 lg:mr-10 flex items-center">
                  <a class="text-gray-800 hover:text-gray-600 pt-10 md:pt-0 cursor-pointer" @click="$router.push('/'); show = false">Home</a>
                </li>
<!--                <li class="md:mr-8 lg:mr-10 flex items-center">
                  <a class="text-gray-800 hover:text-gray-600 pt-10 md:pt-0 cursor-pointer" @click="$router.push('/games')">Games</a>
                </li>-->
                <li class="md:mr-8 lg:mr-10 flex items-center">
                  <a class="text-gray-800 hover:text-gray-600 pt-10 md:pt-0 cursor-pointer" @click="$router.push('/terms-of-service'); show = false">Terms of Service</a>
                </li>
                <li class="md:mr-8 lg:mr-10 flex items-center">
                  <a class="text-gray-800 hover:text-gray-600 pt-10 md:pt-0 cursor-pointer" @click="$router.push('/privacy-policy'); show = false">Privacy Policy</a>
                </li>
                <li class="md:mr-8 lg:mr-10 flex items-center">
                  <a class="text-gray-800 hover:text-gray-600 pt-10 md:pt-0 cursor-pointer" href="mailto:admin@mintry.cc" @click="show = false">Contact</a>
                </li>
<!--                <li class="md:mr-0 flex items-center">
                  <a class="text-gray-800 hover:text-gray-600 pt-10 md:pt-0" href="javascript: void(0)">Contact</a>
                </li>-->
              </ul>
            </div>
          </div>
      </div>
    </header>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'NavBar',
  setup() {
    const show = ref(false)
    return {
      show
    }
  }
})
</script>
