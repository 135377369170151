
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'NavBar',
  setup() {
    const show = ref(false)
    return {
      show
    }
  }
})
