<template>
  <nav-bar />
  <router-view />
  <footer-bar />
</template>
<script>
import NavBar from '@/layout/NavBar'
import FooterBar from '@/layout/FooterBar'
export default {
  name: 'Layout',
  components: {FooterBar, NavBar}
}
</script>
