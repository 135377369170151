import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Layout from '@/layout/Layout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Main',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/Main.vue')
      }
    ]
  },
  {
    path: '/terms-of-service',
    name: 'Terms Of Service',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/TermsOfService.vue')
      }
    ]
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/PrivacyPolicy.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
